import { Serializable } from '@freshheads/javascript-essentials/build/types/utility';
import * as Sentry from '@sentry/browser';
import { isDevEnvironment } from './environmentDetector';

let sentryErrorLoggingInitiated = false;

export function initiateSentryErrorLogging(): void {
    if (sentryErrorLoggingInitiated) {
        return;
    }

    if (!isDevEnvironment) {
        Sentry.init({
            dsn:
                'https://90322a3192961628b1681b7646dae39b@o4507033081020416.ingest.de.sentry.io/4508697549733968',
        });

        sentryErrorLoggingInitiated = true;
    }
}

interface ErrorContext {
    [key: string]: Serializable;
}

export function logError(error: Error, context: ErrorContext): void {
    if (!isDevEnvironment) {
        Sentry.withScope((scope) => {
            try {
                Object.keys(context).forEach((key) =>
                    scope.setExtra(key, context[key])
                );

                Sentry.captureException(error);
            } catch (sentryError) {
                // just in case sentry fails to handle the error, as the system might be down or overloaded sometimes
                // eslint-disable-next-line no-console
                console.error(
                    'Sentry exception capture failed with error: ',
                    sentryError,
                    context
                );
            }
        });
    }

    console.error(error, context);
}

export function logErrorMessage(message: string, context: ErrorContext) {
    logError(new Error(message), context);
}
